<template>
  <a-modal
    :visible="visible"
    title="编辑推广目标"
    :mask-closable="false"
    :width="400"
    :destroyOnClose="true"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" ref="formRef">
      <a-form-item label="名称" v-bind="validateInfos.promoteName">
        <a-input v-model:value="form.promoteName" disabled />
      </a-form-item>
      <a-form-item label="任务目标" v-bind="validateInfos.target">
        <a-radio-group v-model:value="form.target">
          <a-radio :value="1">推广传播</a-radio>
          <a-radio :value="2">留资获客</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="目标人数" v-bind="validateInfos.targetNumber">
        <a-input
          v-model:value="form.targetNumber"
          :placeholder="
            form.target === 1 ? '请输入触及人数（UV）' : '请输入目标留资人数'
          "
        />
      </a-form-item>
      <a-form-item label="预算" v-bind="validateInfos.budget">
        <a-input v-model:value="form.budget" placeholder="请输入预算" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, ref, toRaw, watch } from 'vue';
import { Radio, Form } from 'ant-design-vue';
export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
  },
  props: {
    initValue: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const formRef = ref();
    const form = reactive({
      target: '',
      targetNumber: '',
      budget: '',
      promoteName: '',
    });

    const required = { required: true, message: '不能为空' };
    // const rules = {
    //   target: [required],
    //   targetNumber: [required],
    //   budget: [required],
    //   promoteName: [required],
    // };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      target: [required],
      targetNumber: [
        required,
        {
          pattern: new RegExp(/^[0-9]+([.]{1}[0-9]+){0,1}$/),
          message: '只能输入数值',
        },
        {
          validator: (rule, value, cbfn) => {
            if (Number(value) > 100000000) {
              cbfn('数值过大，请重新输入');
            }
            cbfn();
          },
        },
      ],
      budget: [
        required,
        {
          pattern: new RegExp(/^[0-9]+([.]{1}[0-9]+){0,1}$/),
          message: '只能输入数值',
        },
        {
          validator: (rule, value, cbfn) => {
            if (Number(value) > 100000000) {
              cbfn('数值过大，请重新输入');
            }
            cbfn();
          },
        },
      ],
      promoteName: [required, { max: 128, message: '不可超过128字' }],
    });

    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
        }
      }
    );
    return {
      form,
      // rules,
      formRef,
      validateInfos,
      radioOptions: [
        {
          label: '推广传播',
          value: 1,
        },
        {
          label: '留资获客',
          value: 2,
        },
      ],
      handleOk: () => {
        validate().then(() => context.emit('fnOk', toRaw(form)));
      },
      handleCancel: () => context.emit('update', false),
    };
  },
};
</script>
